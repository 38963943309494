import React from "react";
import { useTranslation } from "react-i18next";
import TranSelect from "../components/TranslateSelect";

import redpanda_hanging from "../img/redpanda_hanging.png";

function Header() {
  const { t } = useTranslation();
  return (
    <header className="header">
      <div className="header__inner">
        <TranSelect />

        <nav>
          <ul className="nav__links">
            <li>
              <a href="#img_grid">{t("Introduce")}</a>
            </li>
            <li>
              <a href="#name">{t("Name")}</a>
            </li>
            <li>
              <a href="#red-list">{t("REDLIST")}</a>
            </li>
            <li>
              <a href="#map">{t("Geographic Range")}</a>
            </li>
            <li>
              <a href="#ref">{t("References")}</a>
            </li>
          </ul>
        </nav>
        <input className="button open-btn" type="button" value="About"></input>
      </div>
      <img className="logo" src={redpanda_hanging} alt="logo"></img>
    </header>
  );
}

export default Header;

import React from "react";
import ReactDOM from "react-dom";
import "../src/styles/venders/bootstrap-reboot.css";
import "../src/styles/loader.css";
import "../src/styles/style.css";
import App from "./App";
import "./i18n";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("global-container")
);

import React, { useEffect } from "react";
import rp_main from "../img/redpanda_main.jpg";
import rp_4 from "../img/4.jpg";

export default function ScrollSection0() {
  useEffect(() => {
    let yOffset = 0; // 現在の位置の高さ
    let prevScrollHeight = 0; //現在のスクロール位置より以前に位置しているセクションたちの高さ
    let currentScene = 0; //現在表示されているスクリーン
    let enterNewScene = false; // 新しいシーンになる瞬間true
    const sceneInfo = [
      {
        // 0
        type: "sticky",
        heightNum: 5, //ブラウザ高さのN倍に scrollHeightをセッティング
        scrollHeight: 0,
        objs: {
          container: document.querySelector("#scroll-section-0"),
          messageA: document.querySelector("#scroll-section-0 .main-message.a"),
          messageB: document.querySelector("#scroll-section-0 .main-message.b"),
          messageC: document.querySelector("#scroll-section-0 .main-message.c"),
          messageD: document.querySelector("#scroll-section-0 .main-message.d")
        },
        values: {
          messageA_opacity_in: [0, 1, { start: 0.1, end: 0.2 }],
          messageB_opacity_in: [0, 1, { start: 0.3, end: 0.4 }],
          messageC_opacity_in: [0, 1, { start: 0.5, end: 0.6 }],
          messageD_opacity_in: [0, 1, { start: 0.7, end: 0.8 }],
          messageA_translateY_in: [20, 0, { start: 0.1, end: 0.2 }],
          messageB_translateY_in: [20, 0, { start: 0.3, end: 0.4 }],
          messageC_translateY_in: [20, 0, { start: 0.5, end: 0.6 }],
          messageD_translateY_in: [20, 0, { start: 0.7, end: 0.8 }],
          messageA_opacity_out: [1, 0, { start: 0.25, end: 0.3 }],
          messageB_opacity_out: [1, 0, { start: 0.45, end: 0.5 }],
          messageC_opacity_out: [1, 0, { start: 0.65, end: 0.7 }],
          messageD_opacity_out: [1, 0, { start: 0.85, end: 0.9 }],
          messageA_translateY_out: [0, -20, { start: 0.25, end: 0.3 }],
          messageB_translateY_out: [0, -20, { start: 0.45, end: 0.5 }],
          messageC_translateY_out: [0, -20, { start: 0.65, end: 0.7 }],
          messageD_translateY_out: [0, -20, { start: 0.85, end: 0.9 }]
        }
      },
      {
        // 1
        type: "sticky",
        heightNum: 5,
        scrollHeight: 0,
        objs: {
          container: document.querySelector("#scroll-section-1"),
          canvas: document.querySelector(".image-blend-canvas"),
          context: document.querySelector(".image-blend-canvas").getContext("2d"),
          imagesPath: [rp_main, rp_4],
          images: []
        },
        values: {
          rect1X: [0, 0, { start: 0, end: 0 }],
          rect2X: [0, 0, { start: 0, end: 0 }],
          blendHeight: [0, 0, { start: 0, end: 0 }],
          canvas_scale: [0, 0, { start: 0, end: 0 }],
          rectStartY: 0
        }
      },

      {
        // 2
        type: "normal",
        heightNum: 5,
        scrollHeight: 0,
        objs: {
          container: document.querySelector("#scroll-section-2")
        }
      }
    ];

    function setCanvasImages() {
      let imgElem;
      for (let i = 0; i < sceneInfo[1].objs.imagesPath.length; i++) {
        imgElem = new Image();
        imgElem.src = sceneInfo[1].objs.imagesPath[i];
        sceneInfo[1].objs.images.push(imgElem);
      }
    }
    function setLayout() {
      for (let i = 0; i < sceneInfo.length; i++) {
        if (sceneInfo[i].type === "sticky") {
          sceneInfo[i].scrollHeight = sceneInfo[i].heightNum * window.innerHeight;
        } else if (sceneInfo[i].type === "normal") {
          sceneInfo[i].scrollHeight = sceneInfo[i].objs.container.offsetHeight;
        }
        sceneInfo[i].objs.container.style.height = `${sceneInfo[i].scrollHeight}px`;
      }
      // 現在のスクロール位置を確認してスクリーンを決める
      yOffset = window.pageYOffset;
      let totalScrollHeight = 0;
      for (let i = 0; i < sceneInfo.length; i++) {
        totalScrollHeight += sceneInfo[i].scrollHeight;
        if (totalScrollHeight >= yOffset) {
          currentScene = i;
          break;
        }
      }
      document.body.setAttribute("id", `show-scene-${currentScene}`);
    }

    function calcValues(values, currentYOffset) {
      let rv;
      const scrollHeight = sceneInfo[currentScene].scrollHeight;
      const scrollRatio = currentYOffset / scrollHeight;
      if (values.length === 3) {
        // start ~ endの間にアニメーションを実行
        const partScrollStart = values[2].start * scrollHeight;
        const partScrollEnd = values[2].end * scrollHeight;
        const partScrollHeight = partScrollEnd - partScrollStart;

        if (currentYOffset >= partScrollStart && currentYOffset <= partScrollEnd) {
          rv = ((currentYOffset - partScrollStart) / partScrollHeight) * (values[1] - values[0]) + values[0];
        } else if (currentYOffset < partScrollStart) {
          rv = values[0];
        } else if (currentYOffset > partScrollEnd) {
          rv = values[1];
        }
      } else {
        rv = scrollRatio * (values[1] - values[0]) + values[0];
      }
      return rv;
    }
    function playAnimation() {
      const objs = sceneInfo[currentScene].objs;
      const values = sceneInfo[currentScene].values;
      const currentYOffset = yOffset - prevScrollHeight;
      const scrollHeight = sceneInfo[currentScene].scrollHeight;
      const scrollRatio = currentYOffset / scrollHeight;
      // eslint-disable-next-line
      switch (currentScene) {
        case 0:
          if (scrollRatio <= 0.22) {
            // in
            objs.messageA.style.opacity = calcValues(values.messageA_opacity_in, currentYOffset);
            objs.messageA.style.transform = `translate3d(0, ${calcValues(
              values.messageA_translateY_in,
              currentYOffset
            )}%, 0)`;
          } else {
            // out
            objs.messageA.style.opacity = calcValues(values.messageA_opacity_out, currentYOffset);
            objs.messageA.style.transform = `translate3d(0, ${calcValues(
              values.messageA_translateY_out,
              currentYOffset
            )}%, 0)`;
          }

          if (scrollRatio <= 0.42) {
            // in
            objs.messageB.style.opacity = calcValues(values.messageB_opacity_in, currentYOffset);
            objs.messageB.style.transform = `translate3d(0, ${calcValues(
              values.messageB_translateY_in,
              currentYOffset
            )}%, 0)`;
          } else {
            // out
            objs.messageB.style.opacity = calcValues(values.messageB_opacity_out, currentYOffset);
            objs.messageB.style.transform = `translate3d(0, ${calcValues(
              values.messageB_translateY_out,
              currentYOffset
            )}%, 0)`;
          }

          if (scrollRatio <= 0.62) {
            // in
            objs.messageC.style.opacity = calcValues(values.messageC_opacity_in, currentYOffset);
            objs.messageC.style.transform = `translate3d(0, ${calcValues(
              values.messageC_translateY_in,
              currentYOffset
            )}%, 0)`;
          } else {
            // out
            objs.messageC.style.opacity = calcValues(values.messageC_opacity_out, currentYOffset);
            objs.messageC.style.transform = `translate3d(0, ${calcValues(
              values.messageC_translateY_out,
              currentYOffset
            )}%, 0)`;
          }

          if (scrollRatio <= 0.82) {
            // in
            objs.messageD.style.opacity = calcValues(values.messageD_opacity_in, currentYOffset);
            objs.messageD.style.transform = `translate3d(0, ${calcValues(
              values.messageD_translateY_in,
              currentYOffset
            )}%, 0)`;
          } else {
            // out
            objs.messageD.style.opacity = calcValues(values.messageD_opacity_out, currentYOffset);
            objs.messageD.style.transform = `translate3d(0, ${calcValues(
              values.messageD_translateY_out,
              currentYOffset
            )}%, 0)`;
          }
          // current scene 1で使うcanvasをあらかじめ描く
          if (scrollRatio > 0.9) {
            const objs = sceneInfo[1].objs;
            const values = sceneInfo[1].values;

            const widthRatio = window.innerWidth / objs.canvas.width;
            const heightRatio = window.innerHeight / objs.canvas.height;
            let canvasScaleRatio;

            if (widthRatio <= heightRatio) {
              // canvasよりブラウザの画面がなが細い場合
              canvasScaleRatio = heightRatio;
            } else {
              // canvasよりブラウザの画面が横細い場合
              canvasScaleRatio = widthRatio;
            }

            objs.canvas.style.transform = `scale(${canvasScaleRatio})`;
            objs.context.fillStyle = "white";
            objs.context.drawImage(objs.images[0], 0, 0);

            // canvas サイズに合わせて innerWidthと innerHeight
            const recalculatedInnerWidth = document.body.offsetWidth / canvasScaleRatio;
            // eslint-disable-next-line
            const recalculatedInnerHeight = window.innerHeight / canvasScaleRatio;

            // 左右ボックスの位置セッティング
            const whiteRectWidth = recalculatedInnerWidth * 0.15;
            values.rect1X[0] = (objs.canvas.width - recalculatedInnerWidth) / 2;
            values.rect1X[1] = values.rect1X[0] - whiteRectWidth;
            values.rect2X[0] = values.rect1X[0] + recalculatedInnerWidth - whiteRectWidth;
            values.rect2X[1] = values.rect2X[0] + whiteRectWidth;

            objs.context.fillRect(parseInt(values.rect1X[0]), 0, parseInt(whiteRectWidth), objs.canvas.height);
            objs.context.fillRect(parseInt(values.rect2X[0]), 0, parseInt(whiteRectWidth), objs.canvas.height);
          }
          break;
        case 1:
          // 縦・横　全ていっぱいにするためにここでセッティング
          const widthRatio = window.innerWidth / objs.canvas.width;
          const heightRatio = window.innerHeight / objs.canvas.height;
          let canvasScaleRatio;
          if (widthRatio <= heightRatio) {
            // canvasよりブラウザの画面がなが細い場合
            canvasScaleRatio = heightRatio;
          } else {
            // canvasよりブラウザの画面が横細い場合
            canvasScaleRatio = widthRatio;
          }
          objs.canvas.style.transform = `scale(${canvasScaleRatio})`;
          objs.context.fillStyle = "white";
          objs.context.drawImage(objs.images[0], 0, 0);

          const recalculatedInnerWidth = document.body.offsetWidth / canvasScaleRatio;
          // eslint-disable-next-line
          const recalculatedInnerHeight = window.innerHeight / canvasScaleRatio;

          if (!values.rectStartY) {
            values.rectStartY =
              objs.canvas.offsetTop + (objs.canvas.height - objs.canvas.height * canvasScaleRatio) / 2;
            values.rect1X[2].start = window.innerHeight / 2 / scrollHeight;
            values.rect2X[2].start = window.innerHeight / 2 / scrollHeight;
            values.rect1X[2].end = values.rectStartY / scrollHeight;
            values.rect2X[2].end = values.rectStartY / scrollHeight;
          }

          // 左右ボックスの位置セッティング
          const whiteRectWidth = recalculatedInnerWidth * 0.15;
          values.rect1X[0] = (objs.canvas.width - recalculatedInnerWidth) / 2;
          values.rect1X[1] = values.rect1X[0] - whiteRectWidth;
          values.rect2X[0] = values.rect1X[0] + recalculatedInnerWidth - whiteRectWidth;
          values.rect2X[1] = values.rect2X[0] + whiteRectWidth;

          // 左右の白いボックスを描く
          objs.context.fillRect(
            parseInt(calcValues(values.rect1X, currentYOffset)),
            0,
            parseInt(whiteRectWidth),
            objs.canvas.height
          );
          objs.context.fillRect(
            parseInt(calcValues(values.rect2X, currentYOffset)),
            0,
            parseInt(whiteRectWidth),
            objs.canvas.height
          );

          if (scrollRatio < values.rect1X[2].end) {
            objs.canvas.classList.remove("sticky");
          } else {
            values.blendHeight[0] = 0;
            values.blendHeight[1] = objs.canvas.height;
            values.blendHeight[2].start = values.rect1X[2].end;
            values.blendHeight[2].end = values.blendHeight[2].start + 0.2;
            const blendHeight = calcValues(values.blendHeight, currentYOffset);
            objs.context.drawImage(
              objs.images[1],
              0,
              objs.canvas.height - blendHeight,
              objs.canvas.width,
              blendHeight,
              0,
              objs.canvas.height - blendHeight,
              objs.canvas.width,
              blendHeight
            );

            objs.canvas.classList.add("sticky");
            objs.canvas.style.top = `${-(objs.canvas.height - objs.canvas.height * canvasScaleRatio) / 2}px`;
            if (scrollRatio > values.blendHeight[2].end) {
              values.canvas_scale[0] = canvasScaleRatio;
              values.canvas_scale[1] = document.body.offsetWidth / (1.5 * objs.canvas.width);
              values.canvas_scale[2].start = values.blendHeight[2].end;
              values.canvas_scale[2].end = values.canvas_scale[2].start + 0.2;

              objs.canvas.style.transform = `scale(${calcValues(values.canvas_scale, currentYOffset)})`;
              objs.canvas.style.marginTop = 0;
            }

            if (scrollRatio > values.canvas_scale[2].end && values.canvas_scale[2].end > 0) {
              objs.canvas.classList.remove("sticky");
              objs.canvas.style.marginTop = `${scrollHeight * 0.4}px`;
            }
          }
          break;
      }
    }

    function scrollLoop() {
      enterNewScene = false;
      prevScrollHeight = 0;
      for (let i = 0; i < currentScene; i++) {
        prevScrollHeight += sceneInfo[i].scrollHeight;
      }
      if (yOffset > prevScrollHeight + sceneInfo[currentScene].scrollHeight) {
        enterNewScene = true;
        currentScene++;
        document.body.setAttribute("id", `show-scene-${currentScene}`);
      }
      if (yOffset < prevScrollHeight) {
        enterNewScene = true;
        if (currentScene === 0) return;
        currentScene--;
        document.body.setAttribute("id", `show-scene-${currentScene}`);
      }
      if (enterNewScene) return;
      playAnimation();
    }
    setCanvasImages();

    window.addEventListener("load", () => {
      setLayout();
      // わざわざ scrollさせる
      // 20回 5px動かせる
      // let tempYOffset = yOffset;
      // let tempScrollCount = 0;
      //   if (tempYOffset > 0) {``
      //     console.log(tempYOffset);
      //     let siId = setInterval(() => {
      //       scrollTo(0, tempYOffset);
      //       tempYOffset += 50;

      //       if (tempScrollCount > 20) {
      //         clearInterval(siId);
      //       }
      //       tempScrollCount++;
      //     }, 20);
      //   }
      window.addEventListener("scroll", () => {
        yOffset = window.pageYOffset;
        scrollLoop();
      });
      window.addEventListener("resize", () => {
        if (window.innerWidth > 900) {
          window.location.reload();
        }
      });
      //   window.addEventListener("orientationchange", () => {
      //     scrollTo(0, 0);
      //     setTimeout(() => {
      //       window.location.reload();
      //     }, 500);
      //   });
    });
  }, []);

  return (
    <div>
      <div className="scroll-section" id="scroll-section-0">
        <h1>Red panda</h1>
        <div className="sticky-elem main-message a">
          <p>Ailurus fulgens</p>
        </div>
        <div className="sticky-elem main-message b">
          <p>小熊貓</p>
        </div>
        <div className="sticky-elem main-message c">
          <p>렛서판다</p>
        </div>
        <div className="sticky-elem main-message d">
          <p>レッサーパンダ</p>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";

import rp_eating from "../img/redpanda_eating.png";
import giant_panda from "../img/giant_panda.png";
import lesser_giant from "../img/lesser_giant.png";

export default function Name() {
  const { t } = useTranslation();
  const { ref, inView } = useInView({
    /* Optional options */
    root: null,
    rootMargin: "-130px 0px",
    threshold: 0,
    once: false
  });
  let flg;
  if (inView) {
    flg = "inview";
  } else {
    flg = "outview";
  }

  return (
    <section id="name" className="name">
      <div className="title">
        <h2 className="title__main">{t("Name")}</h2>
        <p className="title__sub">{t("name.sub")}</p>
      </div>
      <ul className={`name-card appear up ${flg}`} ref={ref}>
        <li className="name-card__item item">
          <figure className="name-card__item__image zoom small" style={{ backgroundImage: `url(${rp_eating})` }}>
            <img src={`url(${rp_eating})`} alt="rp_eating" />
          </figure>
          <div className="name-card__item__desc">
            <p>{t("name.1")}</p>
          </div>
        </li>
        <li className="name-card__item item">
          <figure className="name-card__item__image zoom" style={{ backgroundImage: `url(${giant_panda})` }}>
            <img src={`url(${giant_panda})`} alt="giant_panda" />
          </figure>
          <div className="name-card__item__desc">
            <p>{t("name.2")}</p>
          </div>
        </li>
        <li className="name-card__item item">
          <figure className="name-card__item__image zoom" style={{ backgroundImage: `url(${lesser_giant})` }}>
            <img src={`url(${lesser_giant})`} alt="lesser_giant" />
          </figure>
          <div className="name-card__item__desc">
            <p>{t("name.3")}</p>
          </div>
        </li>
      </ul>
    </section>
  );
}

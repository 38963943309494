import React from "react";
import { useTranslation } from "react-i18next";

function Translate() {
  const { i18n } = useTranslation();

  return (
    <div className="translate__btn">
      <button
        onClick={() => {
          i18n.changeLanguage("en");
        }}
      >
        En
      </button>
      <button
        onClick={() => {
          i18n.changeLanguage("ko");
        }}
      >
        Kr
      </button>
      <button
        onClick={() => {
          i18n.changeLanguage("ja");
        }}
      >
        Jp
      </button>
    </div>
  );
}

export default Translate;

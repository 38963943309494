import React from "react";
import Introduce from "../components/Introduce";
import Name from "../components/Name";
import Redlist from "../components/Redlist";
import Map from "../components/Map";
import Ref from "../components/Ref";
import About from "../components/About";
import SwiperPic from "../components/SwiperPic";
import AsideRight from "../components/AsideRight";
import AsideLeft from "../components/AsideLeft";

export default function Content() {
  return (
    <div id="content">
      <Introduce />
      <Name />
      <Redlist />
      <Map />
      <Ref />
      <About />
      <SwiperPic />
      <AsideLeft />
      <AsideRight />
      {/* <Tran /> */}
    </div>
  );
}

import React from "react";

export default function ScrollSection1() {
  return (
    <div class="scroll-section" id="scroll-section-1">
      <div class="sub-message"></div>
      <canvas class="image-blend-canvas" width="1920" height="1080"></canvas>
    </div>
  );
}

import React from "react";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";

import iucn from "../img/IUCN.png";

export default function Redlist() {
  const { t } = useTranslation();
  const { ref, inView } = useInView({
    /* Optional options */
    root: null,
    rootMargin: "-130px 0px",
    threshold: 0,
    once: false
  });
  let flg;
  if (inView) {
    flg = "inview";
  } else {
    flg = "outview";
  }

  return (
    <section id="red-list" className="red-list">
      <div className="title">
        <h2 className="title__main">{t("REDLIST")}</h2>
        <p className="title__sub">{t("redlist.sub")}</p>
      </div>
      <div className={`red-list__inner appear right ${flg}`} ref={ref}>
        <div className="red-list__logo item">
          <a href="https://www.iucnredlist.org/" target="_blank" rel="noreferrer">
            <img className="zoom" src={iucn} alt="IUCN" />
          </a>
          <p>{t("redlist.IUCN")}</p>
        </div>
        <div className="red-list__info item">
          <div className="red-list__text">
            <p>
              <span className="line">{t("redlist.en")}</span>
            </p>
          </div>
          <div className="red-list__level">
            <div className="red-list__level__dt LC">
              <p>LC</p>
            </div>
            <div className="red-list__level__dt NT">
              <p>NT</p>
            </div>
            <div className="red-list__level__dt VU">
              <p>VU</p>
            </div>
            <div className="red-list__level__dt EN">
              <div className="red-list__redpanda"></div>
              <p>EN</p>
            </div>
            <div className="red-list__level__dt CR">
              <p>CR</p>
            </div>
            <div className="red-list__level__dt EW">
              <p>EW</p>
            </div>
            <div className="red-list__level__dt EX">
              <p>EX</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

import React from "react";
import { useTranslation } from "react-i18next";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper-bundle.min.css";
import "swiper/components/navigation/navigation.min.css";
import no1 from "../img/1.jpg";
import no2 from "../img/2.jpg";
import no3 from "../img/3.jpg";
import no4 from "../img/4.jpg";
import no5 from "../img/5.jpg";
import no6 from "../img/6.jpg";
import no7 from "../img/7.jpg";
import no8 from "../img/8.jpg";
import no9 from "../img/9.jpg";

SwiperCore.use([Navigation, Autoplay]);

export default function SwiperPic() {
  const { t } = useTranslation();

  return (
    <div className="swiper">
      <Swiper
        breakpoints={{
          1024: {
            slidesPerView: 3
          }
        }}
        // onSlideChange={() => console.log("slide change")}
        // onSwiper={swiper => console.log(swiper)}
        spaceBetween={30}
        slidesPerView={1}
        loop={true}
        grabCursor={true}
        parallax={true}
        centeredSlides={true}
        navigation={{
          prevEl: ".button_prev",
          nextEl: ".button_next"
        }}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false
        }}
      >
        <SwiperSlide>
          <div className="hero__memo">{t("swiper.1")}</div>
          <img src={no1} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={no2} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={no3} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={no4} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={no5} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={no6} alt="" />
          <div className="hero__memo top">{t("swiper.2")}</div>
        </SwiperSlide>
        <SwiperSlide>
          <img src={no7} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={no8} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={no9} alt="" />
        </SwiperSlide>
        {/* <div className="swiper-button-next swiper-button-white"></div> */}
        {/* <div className="swiper-button-prev swiper-button-white"></div> */}
      </Swiper>
    </div>
  );
}

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import rp_a from "../img/redpanda_about.png";

export default function About() {
  const { t } = useTranslation();

  useEffect(() => {
    const openBtn = document.querySelector(".open-btn");
    const closeBtn = document.querySelector(".close-btn");
    const modal = document.querySelector("#about");
    openBtn.addEventListener("click", function() {
      modal.style.display = "block";
    });
    closeBtn.addEventListener("click", function() {
      modal.style.display = "none";
    });
  }, []);

  return (
    <div id="about">
      <div className="about">
        <div className="about__inner">
          <input className="close-btn" type="button" value="✕" />
          <div className="about__img">
            <img src={rp_a} alt="" />
          </div>
          <div className="about__message">
            <p>{t("about.dc")}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Content from "../screens/Content";

export default function ScrollSection2() {
  return (
    <div class="scroll-section" id="scroll-section-2">
      <Header />
      <Content />
      <Footer />
    </div>
  );
}

import React from "react";
import Tran from "../components/Translate";

export default function AsideLeft() {
  return (
    <aside class="side left">
      <div class="side__inner">
        <Tran />
      </div>
    </aside>
  );
}

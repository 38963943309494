import React from "react";
import { useTranslation } from "react-i18next";

function TranslateSelect() {
  const { i18n } = useTranslation();
  const [val, setVal] = React.useState("react");

  const handleChange = e => {
    setVal(e.target.value);
    i18n.changeLanguage(e.target.value);
  };

  return (
    <div class="translate__select">
      <select defaultValue="en" value={val} onChange={handleChange}>
        <option value="en">EN</option>
        <option value="ko">KR</option>
        <option value="ja">JP</option>
      </select>
    </div>
  );
}

export default TranslateSelect;

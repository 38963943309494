import React from "react";

export default function Aside() {
  return (
    <aside class="side right">
      <div class="side__inner">
        <span>&copy; Mini Redpanda</span>
      </div>
    </aside>
  );
}

import React from "react";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";

import rp_map from "../img/redpanda_map.jpg";

export default function Map() {
  const { t } = useTranslation();

  const { ref, inView } = useInView({
    /* Optional options */
    root: null,
    rootMargin: "-130px 0px",
    threshold: 0,
    once: false
  });
  let flg;
  if (inView) {
    flg = "inview";
  } else {
    flg = "outview";
  }

  return (
    <section id="map" className="map">
      <div className="title">
        <h2 className="title__main">{t("Geographic Range")}</h2>
        <p className="title__sub">{t("map.sub")}</p>
      </div>
      <div className={`map__inner appear left ${flg}`} ref={ref}>
        <div className="map__image item">
          <img className="zoom" src={rp_map} alt="redpanda_map" />
        </div>
        <div className="map__text item">
          <div className="map__subtitle">
            <span>{t("map.co.title")}</span>
          </div>
          <div className="map__desc">
            <span>{t("map.co")}</span>
          </div>
          <div className="map__subtitle">
            <span>{t("map.dc.title")}</span>
          </div>
          <div className="map__desc">
            <span>{t("map.dc")}</span>
          </div>
        </div>
      </div>
    </section>
  );
}

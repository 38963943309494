import React from "react";
import { useInView } from "react-intersection-observer";

import pexels from "../img/pexels.png";
import pixabay from "../img/pixabay.png";
import unsplash from "../img/unsplash.png";

export default function Ref() {
  const { ref, inView } = useInView({
    /* Optional options */
    root: null,
    rootMargin: "-130px 0px",
    threshold: 0,
    once: false
  });
  let flg;
  if (inView) {
    flg = "inview";
  } else {
    flg = "outview";
  }

  return (
    <section id="ref" className="ref">
      <div className="title">
        <h2 className="title__main">References</h2>
        <p className="title__sub"></p>
      </div>
      <div className={`ref__inner appear down ${flg}`} ref={ref}>
        <div className="ref__docs item">
          <div className="ref__docs__title ">Documents</div>
          <ul className="ref__docs__urls">
            <li>
              <a href="https://www.wwfindia.org/about_wwf/priority_species/red_panda/about_red_panda/">
                About Red panda
              </a>
            </li>
            <li>
              <a href="https://www.iucnredlist.org/species/714/110023718">Redlist: Ailurus fulgens</a>
            </li>
            <li>
              <a href="https://www.iucnredlist.org/about/background-history">Redlist: Background & History</a>
            </li>
            <li>
              <a href="https://nationalzoo.si.edu/animals/news/red-panda-bear-and-more-red-panda-facts">
                Is a Red Panda a Bear?
              </a>
            </li>
          </ul>
        </div>
        <div className="ref__imgs item">
          <div className="ref__imgs__title">Pictures</div>
          <ul className="ref__imgs__urls">
            <li>
              <a href="https://www.pexels.com/">
                <img className="zoom" src={pexels} alt="" />
              </a>
            </li>
            <li>
              <a href="https://unsplash.com/">
                <img className="zoom" src={unsplash} alt="" />
              </a>
            </li>
            <li>
              <a href="https://pixabay.com/">
                <img className="zoom" src={pixabay} alt="" />{" "}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

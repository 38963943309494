import React from "react";
import ScrollSection0 from "./screens/ScrollSection0";
import ScrollSection1 from "./screens/ScrollSection1";
import ScrollSection2 from "./screens/ScrollSection2";

function App() {
  return (
    <div className="container">
      <ScrollSection0 />
      <ScrollSection1 />
      <ScrollSection2 />
    </div>
  );
}

export default App;

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import rp_face from "../img/redpanda_face.jpg";
import rp_face2 from "../img/redpanda_face2.jpg";
import rp_sleep from "../img/redpanda_sleep.jpg";
import rp_sleep2 from "../img/redpanda_sleep2.jpg";
import rp_eat from "../img/redpanda_eat.jpg";
import bamboo from "../img/bamboo.jpg";
import rp_tail from "../img/redpanda_tail.png";
import rp_tree from "../img/redpanda_tree.jpg";
import rp_tree2 from "../img/redpanda_tree2.jpg";
import rp_hide from "../img/redpanda_hide.jpg";
import rp_cute from "../img/redpanda_cute.jpg";

export default function Introduce() {
  const { t } = useTranslation();

  useEffect(() => {
    class ShowExplanation {
      constructor(el) {
        if (el) {
          this.el = document.querySelector(el);
          this.ex_el = document.querySelector(".card-text");
          this._init();
        }
      }
      _init() {
        if (this.el) {
          this.el.addEventListener("mouseover", () => {
            this.ex_el.textContent = this.el.querySelector("p").textContent;
            this.ex_el.style.display = "block";
          });
          this.el.addEventListener("mouseleave", () => {
            this.ex_el.style.display = "none";
          });
        }
      }
    }

    if (window.matchMedia("(min-width: 600px)").matches) {
      // eslint-disable-next-line
      const grid_card_a = new ShowExplanation(".card__item.a");
      // eslint-disable-next-line
      const grid_card_c = new ShowExplanation(".card__item.c");
      // eslint-disable-next-line
      const grid_card_d = new ShowExplanation(".card__item.d");
      // eslint-disable-next-line
      const grid_card_e = new ShowExplanation(".card__item.e");
      // eslint-disable-next-line
      const grid_card_f = new ShowExplanation(".card__item.f");
    }
  }, []);

  return (
    <section id="img_grid" className="img_grid">
      <div className="title">
        <h2 className="title__main">{t("Introduce")}</h2>
        <p className="title__sub">{t("intro.sub")}</p>
      </div>
      <div className="card-text">
        <div className="card-text__inner">
          <p></p>
        </div>
      </div>
      <div className="card-grid">
        <div className="card__item grid-title">
          <h1>Red panda</h1>
        </div>
        <div className="card__item a">
          <figure className="card__item__image" style={{ backgroundImage: `url(${rp_face})` }}>
            <img src="images/redpanda_face2.jpg" alt="redpanda" />
            <figure className="card__item__image__over" style={{ backgroundImage: `url(${rp_face2})` }}>
              <img src="images/redpanda_face.jpg" alt="redpanda" />
            </figure>
          </figure>
          <p>{t("intro.1")}</p>
        </div>
        <div className="card__item b">
          <figure className="card__item__image" style={{ backgroundImage: `url(${rp_sleep2})` }}>
            <img src="images/redpanda_sleep2.jpg" alt="redpanda" />
          </figure>
          <p>{t("intro.2")}</p>
        </div>
        <div className="card__item c">
          <figure className="card__item__image" style={{ backgroundImage: `url(${rp_eat})` }}>
            <img src="images/redpanda_eat.jpg" alt="redpanda" />
            <figure className="card__item__image__over" style={{ backgroundImage: `url(${bamboo})` }}>
              <img src="images/bamboo.jpg" alt="redpanda" />
            </figure>
          </figure>
          <p>{t("intro.3")}</p>
        </div>
        <div className="card__item d">
          <figure className="card__item__image" style={{ backgroundImage: `url(${rp_sleep})` }}>
            <img src="images/redpanda_sleep.jpg" alt="redpanda" />
            <figure className="card__item__image__over" style={{ backgroundImage: `url(${rp_tail})` }}>
              <img src="images/redpanda_tail.png" alt="redpanda" />
            </figure>
          </figure>
          <p>{t("intro.4")}</p>
        </div>
        <div className="card__item e">
          <figure className="card__item__image" style={{ backgroundImage: `url(${rp_tree2})` }}>
            <img src="images/redpanda_tree2.jpg" alt="redpanda" />
            <figure className="card__item__image__over" style={{ backgroundImage: `url(${rp_tree})` }}>
              <img src="images/redpanda_tree.jpg" alt="redpanda" />
            </figure>
          </figure>
          <p>{t("intro.5")}</p>
        </div>
        <div className="card__item f">
          <figure className="card__item__image" style={{ backgroundImage: `url(${rp_hide})` }}>
            <img src="images/redpanda_hide.jpg" alt="redpanda" />
            <figure className="card__item__image__over" style={{ backgroundImage: `url(${rp_cute})` }}>
              <img src="images/redpanda_cute.jpg" alt="redpanda" />
            </figure>
          </figure>
          <p>{t("intro.6")}</p>
        </div>
        <div className="card__item g">
          <div className="card__item__detail">
            <table>
              <tr>
                <th>{t("Lifespan")}</th>
                <td>{t("Lifespan.info")}</td>
              </tr>
              <tr>
                <th>{t("Length")}</th>
                <td>50 ~ 60cm</td>
              </tr>
              <tr>
                <th>{t("Weight")}</th>
                <td>3.7 ~ 6kg</td>
              </tr>
              <tr>
                <th>{t("Population")}</th>
                <td>{t("Population.info")}</td>
              </tr>
              <tr>
                <th>{t("Height")}</th>
                <td>30cm</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
}
